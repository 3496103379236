function getReportButtonCss() {
  return `button.ad__report-button {
    font-size: 2em;
    padding: 0.5em;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.3);
    border: none;
  }

  button.ad__report-button:hover {
    background: rgba(255, 255, 255, 0.7);
  }

  @media (pointer: fine) {
    button.ad__report-button {
      display: none;
    }
    
    .bam-ad-slot:hover button.ad__report-button {
      display: block;
    }
  }`;
}

export default getReportButtonCss;
