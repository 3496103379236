import { fetchConfigFromBam } from "./plugins/helpers/bamHelper";
import { getCookie } from "./plugins/helpers/cookieHelper";
import { convertSpaBam } from "./plugins/helpers/spaBamHelper";
import * as messages from "./plugins/messages";
import eventListeners from "./plugins/eventListeners";
import { refreshAds } from "./plugins/refreshAds";
import { defineSlots, clearSlots, clearSlotIds, defineNonConsensualAds} from "./plugins/defineSlots";
import targetHelper from "./plugins/helpers/targetHelper";
import * as dfpPassback from "./plugins/dfpPassback";
// TODO bryt ut i att sätta alla kakor för targeting
import { setDeliveranceSegmentsCookie } from "./plugins/helpers/segmentsHelper";
import * as bauConsole from "./plugins/bauConsole";
import { devices } from "./static/devices";
import { appendAdInfoStyleSheet } from "./plugins/gdpr";
import { adInfoButtonCss } from "./static/adInfo.js";
import { setBongeoTargeting } from "./plugins/helpers/bongeoHelper";
import { isInternalNetwork } from "./plugins/helpers/internalNetworkHelper";
import { getCategoryTargeting } from "./plugins/helpers/metadataHelper";
import { getDeliveranceConsent, getUserAdsConsent} from "./plugins/helpers/cmpConsentHelper";
import getReportButtonCss from "./static/reportButtonCss";
import { getPublisherProvidedId } from "./plugins/ppid";

let bamDataLoaded = false;
let bauConfig = {};
let initCalled = false;

const timeoutIds = [];
const bundledSlots = [];
const displayAdsQueue = [];
const preloadAdsQueue = [];
const preloadedAds = {};
const bamAdSlotPattern = /(^|\s)bam-ad-slot($|\s)/;

function addAdReporterCss() {
  const reportButtonCss = getReportButtonCss();

  const style = document.createElement("style");
  document.head.appendChild(style);

  style.appendChild(document.createTextNode(reportButtonCss));
}

function inlineGpt() {
  const gptUrl = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
  const gptExists = window.document.querySelector(`script[src='${gptUrl}']`);

  if (!gptExists) {
    const gptScript = window.document.createElement("script");
    gptScript.src = gptUrl;
    window.document.head.appendChild(gptScript);
  }
}

function runQueue() {
  preloadAdsQueue.forEach((adsToPreload) => {
    preloadAds(adsToPreload.scope, adsToPreload.displayConfig);
  });
  displayAdsQueue.forEach((adsToLoad) => {
    displayAds(adsToLoad.scope, adsToLoad.displayConfig);
  });
}

function init(bamData, config) {
  if (typeof window.__tcfapi === "function") {
    window.__tcfapi("addEventListener", 2, (tcData, success) => {
      config = config || {};
      if (success && (tcData.eventStatus === "useractioncomplete" || tcData.eventStatus === "tcloaded")) {
        config.consentStatus = window.Didomi.getUserStatus();
        config.hasConsent = getUserAdsConsent(config.consentStatus);
        config.cmp = { eventListenerId: tcData.listenerId };
        _init(bamData, config);
      }
    }, [1, 2, 3]);
  } else {
    _init(bamData, config);
  }
}

function _init(bamData, config) {
  bamDataLoaded = false;
  window.googletag = window.googletag || {};

  googletag.cmd = googletag.cmd || [];
  config = config || {};

  buildBauConfig(config, bamData, (res, err) => {
    if (err) return;

    bauConfig = res;

    if (bauConfig.targeting && bauConfig.targeting.articleId) {
      getCategoryTargeting(bauConfig, (targetingErr, targeting) => {
        if (targetingErr) return;

        if (targeting) {
          bauConfig.targeting.categories = [].concat(bauConfig.targeting.categories || [], targeting.NITF);
        }

        googletag.cmd.push(() => {
          targetHelper.update(bauConfig.targeting);
        });
      });
    }

    if (bauConfig.spa && initCalled) {
      clearSlots();
      clearSlotIds();
    }

    if (bauConfig.enableAdInfo && bauConfig.adInfo && bauConfig.adInfo.text && !initCalled && !bauConfig.cmp) {
      appendAdInfoStyleSheet(bauConfig.adInfo.text);
    }

    if (bauConfig.isInternal === undefined) {
      isInternalNetwork((_err, _res) => {
        if (_err) return;
        bauConfig.isInternal = _res;
        addAdReporterCss();
      });
    }

    configureGoogleTag(bauConfig);

    dfpPassback.setBauConfig(bauConfig);

    if (!initCalled && !bauConfig.disablePersonalizedAds) {
      if (!bauConfig.cmp || getDeliveranceConsent(config.consentStatus)) {
        setDeliveranceSegmentsCookie();
      }
    }

    bauConsole.init();
    bamDataLoaded = true;

    runQueue();

    initCalled = true;
  });
}

function configureGoogleTag(config) {
  //Push targets
  googletag.cmd.push(() => {
    targetHelper.init(config);
    setBongeoTargeting(config);
  });

  if (!config.disableCollapse && !initCalled) {
    googletag.cmd.push(() => {
      googletag.pubads().collapseEmptyDivs(config.collapseBeforeAdFetch);
    });
  }

  if (!initCalled) {
    // Infinite scroll requires SRA
    googletag.cmd.push(() => {
      if (config.hasConsent) {
        getPublisherProvidedId(config, (_, ppid) => {
          if (ppid) {
            googletag.pubads().setPublisherProvidedId(ppid);
          }
        });
      }
      // enable safeframe
      googletag.pubads().setSafeFrameConfig({ allowOverlayExpansion: false, allowPushExpansion: true, sandbox: true });
      googletag.pubads().enableSingleRequest();
      googletag.pubads().disableInitialLoad();
      googletag.enableServices();
    });
  }

  const requestNonPersonalizedAds = getNPAOption(config);

  if (requestLimitedAds()) {
    googletag.cmd.push(() => {
      googletag.pubads().setPrivacySettings({
        limitedAds: true,
        nonPersonalizedAds: requestNonPersonalizedAds
      });
    });
  } else {
    googletag.cmd.push(() => {
      googletag.pubads().setPrivacySettings({
        nonPersonalizedAds: requestNonPersonalizedAds,
      });
    });
  }


  googletag.cmd.push(() => {
    eventListeners(bauConfig);
  });
}

function getNPAOption(config) {
  if (config.disablePersonalizedAds) return true;
  return false;
}

function requestLimitedAds() {
  return getCookie("bau_limited_ads");
}

function buildBauConfig(config, bamData, callback) {
  bauConfig = extractPropsAndSetDefaults(config);

  if (bamData) {

    const hasDevice = bauConfig.device === devices.DESKTOP || bauConfig.device === devices.TABLET || bauConfig.device === devices.MOBILE;

    if (bauConfig.responsive && hasDevice) {
      bamData.slotNameConfig = createResponsiveSlotNameConfig(bamData.slotNameConfig);
    }

    bamData = setAndConvertBamData(bamData, bauConfig.hostname);

    bauConfig = mergeConfigAndBamData(bauConfig, bamData);

    // Object.assign(bauConfig, getBauConfigProps(bamData));

    return callback(bauConfig);
  } else {
    fetchConfigFromBam(bauConfig.hostname, config.device, bauConfig.pageType, (err, res) => {
      if (err) return callback(null, err);

      window.Bau.bamData = res;

      // Object.assign(bauConfig, getBauConfigProps(res));
      bauConfig = mergeConfigAndBamData(bauConfig, res);

      callback(bauConfig);
    });
  }
}

function setAndConvertBamData(bamData, hostname) {
  if (bamData.spa) {
    window.Bau.spaBamData = bamData;
    bamData = convertSpaBam(bamData, hostname);
  }

  window.Bau.bamData = bamData;
  return bamData;
}

function extractPropsAndSetDefaults(config) {
  return {
    pageType: config.pageType ? config.pageType.toLowerCase() : "default",
    device: config.device ? config.device.toLowerCase() : "unknown",
    slotNameNumberStartIndex: config.slotNameNumberStartIndex,
    responsive: config.responsive,
    targeting: config.targeting || {},
    smartCollapse: config.smartCollapse,
    disableCollapse: config.smartCollapse || config.disableCollapse,
    collapseBeforeAdFetch: config.collapseBeforeAdFetch === undefined ? true : config.collapseBeforeAdFetch,
    hostname: config.hostname?.replace(/^https?:\/\//, ""), // remove http(s) from hostname if it's given.
    enableAdInfo: config.enableAdInfo === undefined ? true : config.enableAdInfo,
    waitForPrebid: config.waitForPrebid === undefined ? true : config.waitForPrebid, // NOTE This will bypass prebid if the bids is not done yet to give lower loadingtimes
    disablePersonalizedAds: config.disablePersonalizedAds
      || (getCookie("npa") === "true")
      || (config.cmp && !(config.consentStatus.purposes.global.enabled.includes("create_ads_profile")
      && config.consentStatus.purposes.global.enabled.includes("select_personalized_ads"))),
    cmp: config.cmp,
    bongeoUrl: config.bongeoUrl || process.env.BONGEO_URL,
    metadataHostname: config.metadataHostname || "assets.bonad.io",
    hasConsent: config.hasConsent !== undefined ? config.hasConsent : true,
    privacyProxyUrl: config.privacyProxyUrl || process.env.PRIVAXY_URL,
    ppid: config.ppid,
    spa: config.spa,
    userCoords: config.userCoords || {},
  };
}

function createResponsiveSlotNameConfig(slotNameConfig) {
  const newSlotNameConfig = {};
  const device = bauConfig.device;

  Object.keys(slotNameConfig).forEach((slotName) => {
    if (slotNameConfig[slotName][device]) {
      newSlotNameConfig[slotName] = slotNameConfig[slotName];
    }
  });

  return newSlotNameConfig;
}

function mergeConfigAndBamData(config, bamData) {
  const extractedBamConfig = {
    adsEnabled: bamData.adsEnabled,
    path: bamData.path,
    prebidEnabled: bamData,
    prebidConfig: bamData.prebidConfig,
    slotNameConfig: bamData.slotNameConfig,
    isExplicit: bamData.isExplicit,
    frequencyCookie: bamData.frequencyCookie,
    adInfo: bamData.adInfo,
    disablePersonalizedAds: bamData.disablePersonalizedAds,
    spa: bamData.spa
  };

  // We want to use bam parameters, overridden by site specific config, and handle some parameters separately
  return {
    ...extractedBamConfig,
    ...config,
    disablePersonalizedAds: extractedBamConfig.disablePersonalizedAds || config.disablePersonalizedAds,
  };
}

function preloadAds(scope, displayConfig) {
  if (!scopePrerequisites(scope, displayConfig, true)) return;
  if (!bauConfig.hasConsent) return;

  processDisplayConfig(displayConfig);

  googletag.cmd.push(() => {
    const slotElements = getSlotElements(scope);
    defineSlots(slotElements, bauConfig, preloadedAds, () => {});
  });
}

function refreshPreloadAds(slotElements) {
  const slotsReadyToRefresh = [], slotElementsNotDone = [];

  for (const slotElement of slotElements) {
    const preloadedSlot = preloadedAds[slotElement.id];

    if (!preloadedSlot) continue; // not preloaded

    if (preloadedSlot.prebidStatus === "done" || !preloadedSlot.prebidStatus) {
      slotsReadyToRefresh.push(preloadedSlot);
    } else if (preloadedSlot.prebidStatus === "in-progress") {
      slotElementsNotDone.push(slotElement);
    }
  }

  refreshAds(slotsReadyToRefresh, bundledSlots, bauConfig.spa);
  if (slotElementsNotDone.length > 0) {
    const refreshTimeoutId = setTimeout(() => {
      refreshPreloadAds(slotElementsNotDone);
    }, 10);
    addTimeoutId(refreshTimeoutId);
  }
}

function displayAds(scope, displayConfig) {
  if (!scopePrerequisites(scope, displayConfig)) return;

  adInfoButtonCss();

  if (bauConfig.hasConsent) {
    processDisplayConfig(displayConfig);
    googletag.cmd.push(() => {
      const slotElements = getSlotElements(scope);

      refreshPreloadAds(slotElements);

      const notPreloaded = [];

      for (const slotElement of slotElements) {
        if (preloadedAds[slotElement.id] === undefined) {
          notPreloaded.push(slotElement);
        }
      }

      defineSlots(notPreloaded, bauConfig, null, (slots) => refreshAds(slots, bundledSlots, bauConfig.spa));
    });
  } else {
    defineNonConsensualAds(getSlotElements(scope), bauConfig, displayConfig);
  }
}

function scopePrerequisites(scope, displayConfig, isPreload) {
  if (!scope) return;

  if (!bamDataLoaded) {
    if (isPreload) {
      preloadAdsQueue.push({ scope, displayConfig });
    } else {
      displayAdsQueue.push({ scope, displayConfig });
    }
    return;
  }

  if (!bauConfig.adsEnabled) {
    window.document.body.classList.toggle("ads-disabled", true);
    return;
  }

  return true;
}

function processTargeting(targeting) {
  googletag.cmd.push(() => {
    targetHelper.update(targeting);
  });

  Object.assign(bauConfig.targeting, targeting);

  if (targeting?.articleId) {
    getCategoryTargeting({ targeting, metadataHostname: bauConfig.metadataHostname }, (targetingErr, categories) => {
      if (targetingErr) return;

      if (categories) {
        targeting.categories = [].concat(targeting.categories || [], categories.NITF);
      }

      Object.assign(bauConfig.targeting, targeting);

      googletag.cmd.push(() => {
        targetHelper.update(bauConfig.targeting);
      });
    });
  }
}

function processDisplayConfig(displayConfig) {
  displayConfig = displayConfig || {};

  processTargeting(displayConfig.targeting);

  bauConfig.infinityScroll = displayConfig.infinityScroll || null;
  bauConfig.resetAds = displayConfig.resetAds || null;
  bauConfig.slotTargeting = displayConfig.slotTargeting || null;
  bauConfig.slotSizes = displayConfig.slotSizes || null;
}

function getSlotElements(scope) {
  if (scope.className && bamAdSlotPattern.test(scope.className)) {
    return [scope];
  } else {
    return [...scope.getElementsByClassName("bam-ad-slot")];
  }
}

function initiateDfpPassback(data) {
  if (!data || !data.slotId) return;

  const lineItemIds = data.lineItemIds ? data.lineItemIds.split(",") : [];

  dfpPassback.initPassback(data.slotId, lineItemIds);
}

function openConsole(isLocal) {
  bauConsole.openConsole(isLocal);
}

const events = {
  subscribe: messages.subscribe,
  unsubscribe: messages.unsubscribe
};

function clear() {
  clearSlots();
  clearSlotIds();
  dfpPassback.setBauConfig(null);
  bauConfig = {};
  bamDataLoaded = false;
  initCalled = false;
  for (const key of Object.keys(preloadedAds)) delete preloadedAds[key];
  bundledSlots.splice(0);
  messages.reset();
  if (window.__tcfapi && bauConfig.cmp?.eventListenerId) {
    window.__tcfapi("removeEventListener", 2, () => {}, bauConfig.cmp.eventListenerId);
  }
  for (const id of timeoutIds) clearTimeout(id);
}

function refreshSlots() {
  googletag.cmd.push(() => {
    googletag.pubads().refresh(null, {changeCorrelator: true});
  });
}

function resetSlots() {
  googletag.cmd.push(() => {
    googletag.destroySlots();
  });
}

function addTimeoutId(id) {
  timeoutIds.push(id);
}

export {
  inlineGpt,
  init,
  preloadAds,
  displayAds,
  clear, // only for tests
  initiateDfpPassback,
  openConsole,
  events,
  refreshSlots,
  resetSlots,
  addTimeoutId
};
