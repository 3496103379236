import { publish as publishMessage } from "./messages";
import * as passbackHelper from "./helpers/passbackHelper";
import trackAd from "./trackAd";
import * as cookieHelper from "./helpers/cookieHelper";
import { slotNames } from "../static/slotNames";
import triggerFluidVideo from "./fluidRichMediaPremium";
import { parseEvent } from "./slotEvent";
import { createReportButton } from "./adReportButton";
import { setAdInfoClickLink } from "./gdpr";
import { getSlotConfig } from "./helpers/bamHelper";
import noScaleLineitems from "../static/lineItemsToNotScale";
import excludedSites from "../static/excludedSitesFromTintin";

function eventListeners(bauConfig) {
  googletag.pubads().addEventListener("slotRenderEnded", (evt) => slotRenderEndedListener(evt, bauConfig));
  googletag.pubads().addEventListener("impressionViewable", impressionViewableEventListener);

  window.addEventListener("message", messageHandler);
}

function slotRenderEndedListener(evt, bauConfig) {
  const adUnit = parseEvent(evt, "slotRenderEnded");
  triggerSlotRenderEndedEvents(adUnit, bauConfig);
  createSlotAttributes(adUnit);
  setFrequencyCookie(adUnit, bauConfig.frequencyCookie);
  triggerFluidVideo(adUnit);
  if (!excludedSites.find((it) => window.location.hostname.replace(/^www\./, "").match(it))) {
    createReportButton(adUnit, bauConfig);
  } else {
    setAdInfoStyling(adUnit, bauConfig);
    if (bauConfig.isInternal) {
      createReportButton(adUnit, bauConfig);
    }
  }

  // Unique logic for previewing ads with a generated GAM preview link.
  if (adUnit.adData.lineItemId === null && new URLSearchParams(location.href).get("lineItemId")) {
    adUnit.adData.lineItemId = Number(new URLSearchParams(location.href).get("lineItemId"));
  }

  if (!noScaleLineitems.includes(adUnit.adData.lineItemId) && (adUnit.name === "rich_media_premium" || (adUnit.name === "mob_rich_media_premium" && !evt.slot.getHtml().includes("8b38f539-3ae1-4df4-af53-b2c9013bbe59")))) {
    const slotElement = document.getElementById(adUnit.id);
    scaleRichMediaPremium(slotElement);
    window.addEventListener("resize", () => {
      scaleRichMediaPremium(slotElement);
    });
  } else if (adUnit.name === "rich_media_premium") {
    const slotElement = document.getElementById(adUnit.id);
    slotElement.style.display = "flex";
    slotElement.style.justifyContent = "center";

    // We scale down the ad once if needed but never up.
    const maxWidthScale = window.visualViewport.width / 1920;
    const maxHeightScale = window.visualViewport.height / 1080;

    const scale = Math.min(maxHeightScale, maxWidthScale, 1);

    slotElement.firstChild.style.transform = `scale(${scale})`;
    slotElement.firstChild.style.transformOrigin = "center top";
  } else if (adUnit.name === "mob_rich_media_premium") {
    const slotElement = document.getElementById(adUnit.id);
    slotElement.style.display = "flex";
    slotElement.style.justifyContent = "center";

    const maxWidthScale = window.visualViewport.width / adUnit.size.width;
    const maxHeightScale = window.visualViewport.height / adUnit.size.height;

    const scale = Math.min(maxHeightScale, maxWidthScale, 1);

    slotElement.firstChild.style.transform = `scale(${scale})`;
    slotElement.firstChild.style.transformOrigin = "center top";
  }
}

function scaleRichMediaPremium(slotElement) {
  if (!window?.visualViewport || !slotElement) return;

  const safeArea = {
    desktop: {
      width: 1920,
      height: 817 // 777 + 40
    },
    mob: {
      width: 640,
      height: 793 // 753 + 40
    }
  };

  let maxWidthScale;
  let maxHeightScale;

  if (slotElement.dataset.slotName === "rich_media_premium") {
    maxWidthScale = window.visualViewport.width / safeArea.desktop.width;
    maxHeightScale = (window.visualViewport.height - slotElement.getBoundingClientRect().y) / safeArea.desktop.height;
  } else {
    maxWidthScale = window.visualViewport.width / safeArea.mob.width;
    maxHeightScale = (window.visualViewport.height - slotElement.getBoundingClientRect().y) / safeArea.mob.height;
  }
  const scale = Math.min(maxHeightScale, maxWidthScale);

  slotElement.style.display = "flex";
  slotElement.style.justifyContent = "center";

  slotElement.firstChild.style.transformOrigin = "top";
  slotElement.firstChild.style.transform = `scale(${scale})`;
}

function setAdInfoStyling(adUnit, bauConfig) {
  if (!bauConfig.enableAdInfo) return;
  const slotElement = document.getElementById(adUnit.id);
  if (!slotElement) return;
  const slotInfo = bauConfig.slotNameConfig[adUnit.name];
  const slot = getSlotConfig(adUnit.number, slotInfo.slots);

  if (bauConfig.adInfo && bauConfig.adInfo.link) {
    if (slot.adInfoEnabled && !adUnit.isEmpty) {
      slotElement.classList.add("ad-info");
      setAdInfoClickLink(slotElement, bauConfig.adInfo.link);
    }
  }
}

function setFrequencyCookie(adUnit, frequencyCookie) {
  if (!(frequencyCookie && frequencyCookie.name && frequencyCookie.expire)) return;
  if (adUnit.name !== slotNames.RICH_MEDIA_PREMIUM && adUnit.name !== slotNames.MOB_RICH_MEDIA_PREMIUM) return;
  cookieHelper.setCookie(frequencyCookie.name, true, frequencyCookie.expire / 24, undefined, "Lax");
}

function createSlotAttributes(adUnit) {
  const element = window.document.getElementById(adUnit.id);
  if (element === null) return;

  const creativeIds = element.getAttribute("data-creative-ids") ? element.getAttribute("data-creative-ids").split(",") : [];
  creativeIds.push(adUnit.adData.creativeId);
  element.setAttribute("data-creative-ids", creativeIds.join(","));

  const lineItemIds = element.getAttribute("data-line-item-ids") ? element.getAttribute("data-line-item-ids").split(",") : [];
  lineItemIds.push(adUnit.adData.lineItemId);
  element.setAttribute("data-line-item-ids", lineItemIds.join(","));
}

function impressionViewableEventListener(evt) {
  const eventData = parseEvent(evt, "impressionViewable");
  publishMessage("impressionViewable", eventData);
  const { campaignId, lineItemId, creativeId } = eventData.adData;
  if (campaignId && lineItemId && creativeId) {
    triggerExposedAdAudiencePixel(campaignId, lineItemId, creativeId);
  }
}

function triggerSlotRenderEndedEvents(adUnit, bauConfig) {
  publishMessage("slotRenderEnded", adUnit);

  if (!adUnit.isEmpty) {
    if (window.pbjs && window.pbjs.creatives) {
      adUnit = prebidCreativeSize(adUnit);
    }
    if (adUnit.adData.path.indexOf("passback") > -1) {
      const slotElement = window.document.getElementById(adUnit.id);
      trackAd(slotElement, window.top.innerHeight, "bottom", "passback", () => passbackHelper.resize(slotElement));
    }
    publishMessage("resize", adUnit);
  } else {
    if (bauConfig.smartCollapse) {
      const smartCollapseOffset = bauConfig.smartCollapse === "below" ? window.top.innerHeight : 0;
      const smartCollapseDirection = "top";
      trackAd(window.document.getElementById(adUnit.id), smartCollapseOffset, smartCollapseDirection, "smartCollapse", () => {
        publishMessage("collapse", adUnit);
      });
      publishMessage("empty", adUnit);
    } else {
      publishMessage("collapse", adUnit);
    }
  }
}

function prebidCreativeSize(adUnit) {
  const creatives = Array.prototype.slice.call(window.pbjs.creatives);
  const filteredCreatives = [];

  creatives.forEach((creative) => {
    if (creative.id === adUnit.adData.creativeId) {
      const size = creative.size.split("x");
      adUnit.size = {
        "width": parseInt(size[0]),
        "height": parseInt(size[1])
      };
    } else {
      filteredCreatives.push(creative);
    }
  });

  window.pbjs.creatives = filteredCreatives;

  return adUnit;
}

function triggerExposedAdAudiencePixel(campaignId, lineItemId, creativeId) {
  const axel = Math.random() + ''; // eslint-disable-line
  const a = axel * 10000000000000;
  (new Image()).src = `https://pubads.g.doubleclick.net/activity;dc_iu=/34405621/DFPAudiencePixel;ord=${a};dc_seg=480964028;campaignId=${campaignId};lineItemId=${lineItemId};creativeId=${creativeId}`;
}

function createAdLabel(fontSize, padding, bamAdSlotId) {
  const adLabel = document.createElement("div");
  adLabel.textContent = "ANNONS";
  adLabel.style.cssText = `color:white;background-color: hsla(0, 0%, 0%, 0.50);font-size: ${fontSize};position:fixed;top:0px;left:0px;font-weight: 700;padding:${padding};z-index: 1;`;
  document.getElementById(bamAdSlotId).appendChild(adLabel);
}

function createArrow(arrowWidth, arrowHeight, bamAdSlotId) {
  const arrow = document.createElement("div");
  arrow.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" style="filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.5));" width="${arrowWidth}" height="${arrowHeight}" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-down">
      <polyline points="7 13 12 18 17 13" />
      <polyline points="7 6 12 11 17 6" />
  </svg>`;
  arrow.style.cssText = `position: absolute; bottom: 0px; left: 0px; width: 100%;height: ${arrowHeight} !important; display: flex !important; align-items: center; justify-content: center; flex-direction: column; pointer-events: none;background-color: hsla(0, 0%, 0%, 0);`;
  document.getElementById(bamAdSlotId).appendChild(arrow);
}

function createCloseButton(closeButtonSize, bamAdSlotId) {
  const closeButton = document.createElement("div");
  closeButton.innerHTML = `<svg style="width: ${closeButtonSize};filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.5));" viewBox="0 0 100 100">
      <path d="M0,0 L100,100 M0,100 L100,0" style="fill:none;stroke:white;stroke-width:15"></path>
  </svg>`;
  closeButton.style.cssText = `width: ${closeButtonSize};position: fixed;top: 25px;right: 10px`;
  closeButton.addEventListener("click", () => {
    /*
      There is a choice to be made if we decide on removing the google slot from the page or just scroll past.

      1. if we do remove it we should send out a event that the slot has been collapsed and let the site handle it.

      2. Scroll down to under it, this is the current implementation.
    */

    // Option 1.
    // googletag.destroySlots([googletag.pubads().getSlots().find((slot) => slot.getSlotElementId() === bamAdSlotId)]);
    // publishMessage("collapse", {
    //   slotName: bamAdSlotId.split("-")[0],
    //   slotNumber: bamAdSlotId.split("-")[1],
    //   id: bamAdSlotId,
    // });

    // Option 2.
    const contentStartHeight = window.visualViewport.pageTop + document.getElementById(bamAdSlotId).getBoundingClientRect().bottom + 1; //+ 1 to make sure the ad has been scrolled down all the way.

    window.scrollTo({ behavior: "smooth", top: contentStartHeight});
  });
  document.getElementById(bamAdSlotId).appendChild(closeButton);
}


function messageHandler(event) {
  if (event.origin === "https://video.seenthis.se") {
    try {
      const data = JSON.parse(event.data);
      const adIframe = document.querySelector(`iframe[src*="${data.adframe}"]`);
      const bamAdSlot = adIframe.closest(".bam-ad-slot");
      if (["rich_media_premium", "mob_rich_media_premium"].includes(bamAdSlot.dataset.slotName) && (adIframe.width === "1920" || adIframe.width === "640") && data.action === "responsive") {
        bamAdSlot.style.height = `${window.innerHeight - bamAdSlot.getBoundingClientRect().y}px`;
        const adIframeId = adIframe.id;
        const responsiveStyle = document.createElement("style");
        responsiveStyle.id = "responsiveStyle";
        responsiveStyle.innerHTML = `
[data-slot-name='rich_media_premium'] [id*='${adIframeId}'],
[data-slot-name='mob_rich_media_premium'] [id*='${adIframeId}']{
width: 100% !important;
height: 100% !important;
transform: none !important;
}
`;
        document.head.appendChild(responsiveStyle);
      } else if (["rich_media_premium", "mob_rich_media_premium"].includes(bamAdSlot.dataset.slotName) && (adIframe.width === "1920" || adIframe.width === "640") && data.action === "topscroll") {
        const adIframeId = adIframe.id;
        const bamAdSlotId = bamAdSlot.id;
        const topscrollStyle = document.createElement("style");
        const peakAmount = "calc(70vh)";
        topscrollStyle.textContent = `
.fullpage-ad__header { display: none !important; }
div:has(> div#${bamAdSlotId}) { height: ${peakAmount} !important; }
div#${bamAdSlotId} { height: ${peakAmount} !important; clip-path: polygon(0px 0px, 100vw 0px, 100vw ${peakAmount}, 0px ${peakAmount}) !important; margin: 0px !important; padding: 0px !important; position: relative !important;}
[data-slot-name='rich_media_premium'] [id*='${adIframeId}'],
[data-slot-name='mob_rich_media_premium'] [id*='${adIframeId}'] { height: ${peakAmount} !important; width: 100vw !important; clip: rect(auto, auto, auto, auto) !important; position: fixed !important; left: 0px !important; transform: none !important;}
@keyframes moveAndFade{0%{transform:translateY(0);opacity:1}50%{transform:translateY(50%);opacity:0}100%{transform:translateY(100%);opacity:0}}.feather.feather-chevrons-down polyline{animation:2s linear infinite moveAndFade}
`;
        top.document.head.appendChild(topscrollStyle);


        if (bamAdSlotId.includes("mob")) {
            createAdLabel("10px", "3px 8px", bamAdSlotId);
            createArrow("50px", "70px", bamAdSlotId);
            createCloseButton("20px", bamAdSlotId);
        } else {
            createAdLabel("14px", "5px 10px", bamAdSlotId);
            createArrow("70px", "90px", bamAdSlotId);
            createCloseButton("30px", bamAdSlotId);
        }
      } else if (["mob"].includes(bamAdSlot.dataset.slotName) && data.action === "mobile_mega") {
        const adIframeId = adIframe.id;
        const mobileMegaStyle = document.createElement("style");
        mobileMegaStyle.textContent = `
        div:has(> [id='${adIframeId}']) {height: auto !important; width: 100% !important;}
        [id='${adIframeId}'] {min-width: 100% !important;}
        `;
        top.document.head.appendChild(mobileMegaStyle);
      } else if (["mob"].includes(bamAdSlot.dataset.slotName) && data.action === "midscroll") {
        const headerHeight = "46px";
        const peakAmount = `calc(100vh - ${headerHeight})`;
        const adIframeId = adIframe.id;
        const bamAdSlotId = bamAdSlot.id;
        const midscrollStyle = document.createElement("style");
        midscrollStyle.textContent = `
        div#${bamAdSlotId} { height: ${peakAmount} !important; clip-path: polygon(0px 0px, 100vw 0px, 100vw ${peakAmount}, 0px ${peakAmount}) !important; margin: 0px 0px 0px -8px !important; padding: 0px !important; position: relative !important;}
        [id='${bamAdSlotId}'] [id*='${adIframeId}'],
    [id='${bamAdSlotId}'] [id*='${adIframeId}'] { height: calc(100vh - ${headerHeight}) !important; width: 100vw !important;clip: rect(auto, auto, auto, auto) !important; position: fixed !important; left: 0px !important; top: ${headerHeight} !important;}
        [id='${bamAdSlotId}'] [class*='ad-info_button'] {display: none !important;}
        `;
        top.document.head.appendChild(midscrollStyle);

        if (bamAdSlotId.includes("mob")) {
            createAdLabel("10px", "3px 8px", bamAdSlotId);
        } else {
            createAdLabel("14px", "5px 10px", bamAdSlotId);
        }
      }
    } catch (e) {
      // Do nothing
    }
  }
}
export default eventListeners;
